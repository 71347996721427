/* modal */
:not(.modal-alert) {
	.modal-dialog {
		&:not(.modal-transparent) {
			.modal-content {
				box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.25);
				background-color: #383b40;
				color: #a3acb5;
			}
		}
	}
}

.swal2-popup {
	box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.25);
	background-color: #383b40;
	color: #a3acb5;
}

.swal2-title {
	color: #ffffff;
}

.modal-transparent .modal-content {
	background: rgba(0, 0, 0, 0.75);
	box-shadow: none;
}

.modal-title {
	color: $dark-content-color;
}