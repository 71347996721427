//Variables for Dark MODE
$dark-content-background: #37393e;
$dark-content-links: var(--theme-primary-200); //defaults to #ffffff
$dark-content-color: #a5abb1;
$border-width: 1px;
$border-color: rgba(0,0,0,0.15);
$dark-subheader-title-color: $dark-content-color;
$dark-header-bottom-border-color: #252525;
$dark-nav-link-color: $dark-content-color;
$dark-nav-link-active-color: #ffffff;
$dark-nav-background: #212225;
$dark-black: #000000;
$dark-white: #ffffff;


$rgba-primary: #886ab5;
$rgba-success: #1dc9b7;
$rgba-info: #2196F3;
$rgba-warning: #ffc241;
$rgba-danger: #fd3995;
$rgba-fusion: #505050;